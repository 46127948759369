(function () {
  'use strict';
  // jscs:disable maximumLineLength

  angular
    .module('wbAccount')
    .config(config);

  function config($translateProvider) {
    $translateProvider.translations('hu', {
      'account.change': 'Profil szerkesztése',
      'account.change.submit': 'Személyes adatok mentése',
      'account.personalData': 'Személyes adatok',
      'account.titleId': 'Megszólítás',
      'account.titleId.1': 'Úr',
      'account.titleId.2': 'Hölgy',
      'account.name': 'Teljes név',
      'account.lastName': 'Családi név',
      'account.firstName': 'Utónév',
      'account.contact': 'Elérhetőségek',
      'account.contact.email': 'E-mail',
      'account.contact.phone': 'Telefonszám (opcionális)',
      'account.contact.fax': 'Fax (opcionális)',
      'account.contact.vatNumber': 'Adóazonosító jel',
      'account.save': 'Mentés',
      'account.updated': 'Adatait elmentettük.',
      'account.company.change': 'Cégadatok szerkesztése',
      'account.company.updated': 'Cége adatait elmentettük.',
      'account.company.companyData': 'Cégadatok',

      'account.company.company.change': 'Cégadatok szerkesztése',
      'account.company.name': 'Cég név',
      'account.company.companyNumber': 'Cégjegyzékszám',
      'account.company.vatNumber': 'Adóazonosító jel',
      'account.company.bankAccountNumber': 'Bankszámlaszám',
      'account.company.staffCount': 'Munkavállalók száma',
      'account.company.staffName': 'Kártyabirtokos neve',
      'account.company.stolenCard.csn': 'Elveszett/ellopott/rongáló dott kártya 8 számjegyű kártyaazonosítója',
      'account.company.nameOnCard': 'Kártyán szereplő cégnév',
      'account.company.nameOnCard.tooltip': 'A kártyan szereplő név<br/>legfeljebb 21 karakter hosszú lehet.',
      'account.company.contact': 'Elérhetőségek',
      'account.company.contact.invoiceEmail': 'E-mail-cím',

      'account.company.address': 'Székhely',
      'account.company.address.zipCode': 'Irányítószám',
      'account.company.address.city': 'Város',
      'account.company.address.address': 'Cím',
      'account.company.address.countryCode': 'Ország',

      'account.company.invoiceAddress': 'Számlázási cím',
      'account.company.invoiceAddress.zipCode': 'Irányítószám',
      'account.company.invoiceAddress.city': 'Város',
      'account.company.invoiceAddress.address': 'Cím',
      'account.company.invoiceAddress.countryCode': 'Ország',

      'account.company.deliveryName': 'Szállítási név',
      'account.company.deliveryName.name': 'Átvevő neve',
      'account.company.deliveryAddress': 'Szállítási cím',
      'account.company.deliveryAddress.zipCode': 'Irányítószám',
      'account.company.deliveryAddress.city': 'Város',
      'account.company.deliveryAddress.address': 'Cím',
      'account.company.deliveryAddress.countryCode': 'Ország',

      'account.company.owner': 'Tulajdonos adatai',
      'account.company.owner.titleId': 'Megszólítás',
      'account.company.owner.name': 'Cégtulajdonos neve',
      'account.company.owner.birthName': 'Születéskori név (ha eltér)',
      'account.company.owner.post': 'Beosztás',
      'account.company.owner.nationality': 'Állampolgárság',
      'account.company.owner.IDType': 'Azonosító okmány típusa',
      'account.company.owner.IDNumber': 'Okmány száma',

      'account.IDType.1': 'Személyi igazolvány',
      'account.IDType.2': 'Útlevél',
      'account.IDType.3': 'Vezetői engedély',

      'account.company.owner.isForeign': 'Külföldi állampolgár',
      'account.company.owner.phone': 'Telefonszám (opcionális)',
      'account.company.owner.fax': 'Fax (opcionális)',
      'account.company.owner.email': 'E-mail-cím (opcionális)',

      'account.company.owner.address': 'Tulajdonos lakcíme',
      'account.company.owner.hungarianAddress': 'Magyarországi cím',

      'account.company.save': 'Mentés',
      'account.newCardOrder.legend': 'Ebben a menüpontban rendelheti meg dolgozói, partnerei részére az új kártyákat. Kattintson a Tovább gombra és pár lépésben le tudja adni a kártyarendelést.',
      'account.topUp.legend': 'Ebben a menüpontban tudja feltölteni a már megrendelt kártyákat. Kattintson a Tovább gombra és pár lépésben le tudja adni a kártyafeltöltést.',
      'account.more': 'Tovább',

      'account.company.openAlert': 'Tisztelt Ügyfelünk!<br><br>Felhívjuk szíves figyelmét, hogy az adatmódosítás <strong>1 munkanapon</strong> belül kerül frissítésre a rendszereinkben. Amennyiben cégnevet/adószámot módosít, akkor ahhoz új szerződés adatlap szükséges, emiatt kérjük vegye fel velünk a kapcsolatot a 06-1/413-333-as központi telefonszámon!<br><br>Köszönettel: Rewin csapata',

      'account.company.noCompany': 'Felhasználójához nem sikerült céget találnunk.<br><br>A hibáról munkatársaink értesültek, jelentkezni fognak az Ön által megadott e-mail-címen ({{ email }}).<br><br>Megértését köszönjük.',

      'csnFile.title': 'Kártyaazonosító letöltése',
      'csnFile.date': 'Dátum',
      'csnFile.generateDate': 'Generálás ideje',
      'csnFile.file': 'Fájl',
      'csnFile.download': 'Letöltés',
      'csnFile.info': 'Teljes kártyaazonosító-listáért kérjük válassza a "Kártyaazonosítók teljes listái" menüpontot.',

      'completeCsnLists.title': 'Kártyaazonosítók teljes listái',
      'completeCsnLists.downloadListInfo': 'A kártyaazonosítókat tartalmazó teljes listáért kattintson a letöltés gombra.',
      'completeCsnLists.downloadList': 'Teljes lista letöltése',
      'completeCsnLists.card.type.1': 'Inaktív',
      'completeCsnLists.card.type.2': 'Aktív',
      'completeCsnLists.card.type.3': 'Elveszett/Lopott/Sérült',
      'completeCsnLists.card.type.4': 'Blokkolt',
      'completeCsnLists.table.header.csn': 'Szériaszám',
      'completeCsnLists.table.header.nameOnCard': 'Kártyán szereplő név',
      'completeCsnLists.table.header.expiryDate': 'Lejárat',
      'completeCsnLists.table.header.cardType': 'Kártyatípus',
      'completeCsnLists.table.header.status': 'Státusz',
      'completeCsnLists.cardType.null.title': 'Ismeretlen kártyatípus',
      'completeCsnLists.cardType.5.title': 'Edenred Bevásárló Kártya',
      'completeCsnLists.cardType.6.title': 'Edenred Iskolakezdési Kártya',
      'completeCsnLists.cardType.8.title': 'Ajándék Arany kártya',
      'completeCsnLists.cardType.9.title': 'Kultúra és Sport kártya',
      'completeCsnLists.cardType.10.title': 'Ajándék Ezüst kártya',
      'completeCsnLists.cardType.11.title': 'Edenred Motivációs Kártya',
      'completeCsnLists.cardType.15.title': 'Ezüst Kártya',
      'completeCsnLists.cardType.16.title': 'Arany Kártya',
      'completeCsnLists.cardType.17.title': 'Platinum Kártya',
      'completeCsnLists.cardType.28.title': 'Edenred Egyedi Kártya',
      'completeCsnLists.cardType.29.title': 'Komfort Kártya',
      'completeCsnLists.cardType.30.title': 'Edenred Ticket Restaurant SZÉP Kártya',
      'completeCsnLists.cardType.31.title': 'Edenred Gyerek Kártya',
      'completeCsnLists.cardType.32.title': 'Edenred Társ Kártya',
      'completeCsnLists.cardType.33.title': 'Edenred Szülő Kártya'
    });
  }
}());
